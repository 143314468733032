
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({})
export default class StepList extends Vue {
  @Prop({ default: () => [] })
  data: any;
  @Prop({ default: 0 })
  total: number | string;
  get $data() {
    return this.data;
  }
  isLoadMore: boolean = false;
  loadMore() {
    this.$emit("loadMore");
    if (this.total === 0) {
      this.isLoadMore = !this.isLoadMore;
    } else {
      this.isLoadMore = true;
    }
  }
}
