
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import StepList from "@/components/scope/step-list.vue";
import dayjs from "dayjs";

const service = namespace("service");
const store = namespace("store");
const setting = namespace("setting");
const user = namespace("user");
@Component({
  components: {
    StepList
  }
})
export default class ServiceView extends Vue {
  @service.Action getOrderDetail;
  dayjs = dayjs;
  splitThousands = splitThousands;
  get breadData() {
    return [
      { name: this.$t("test.service-management"), path: "/service/index" },
      { name: this.$t("service.check-service") }
    ];
  }
  get vehicleArrivalImgs() {
    return this.detailData.photoList.filter(item => item.photoType === 1);
  }
  get vehicleWorkingImgs() {
    return this.detailData.photoList.filter(item => item.photoType === 3);
  }
  logData: any[] = [];
  appointmentData: any = [];
  get tenantCode() {
    return this.$route.query.tenantCode;
  }
  get hasAppointmentDate() {
    return this.appointmentData.map(item => item.date);
  }
  created() {
    this.init();
  }
  get orderId() {
    return this.$route.params.id;
  }
  emptyToLine = emptyToLine;
  detailData: any = { orderStatus: "9", photoList: [] };
  storeData: any = {};
  get additionalList() {
    if (!this.detailData.additionalFees) {
      return [];
    }
    return [
      {
        additionalFees: this.detailData.additionalFees,
        additionalFeesRemark: this.detailData.additionalFeesRemark,
        payAdditionalFees: this.detailData.payAdditionalFees
      }
    ];
  }
  invoiceInfo: any = {};
  get complexOrderStatusStr() {
    if (this.detailData.orderStatus === "4" || this.detailData.orderStatus === "9") {
      return this.detailData.orderStatusDesc;
    } else {
      return this.detailData.channelOrderStatusDesc || this.detailData.orderStatusDesc;
    }
  }
  init() {
    this.getOrderDetail({
      orderNo: this.orderId,
      tenantCode: this.tenantCode
    }).then(data => {
      data.data.customerOilVolume = Number(data.data.customerOilVolume);
      this.detailData = data.data;
      if(data.data.photoList && data.data.photoList.length > 0){
        data.data.photoList.forEach(item =>{
          if(item.photoPath.indexOf("?") == -1 && item.photoPath.endsWith(".heic")){
            item.photoPath = item.photoPath + "?x-oss-process=image/format,png";
          }
        })
        this.detailData.photoList = data.data.photoList;
      }
    });
  }
  toReceiptView(row){
    const { href } = this.$router.resolve(`/finance/collection-order-view/${row.receiptNo}`);
    window.open(href);
  }

  handleUserView(row) {
    let { href } = this.$router.resolve({
      path: `/customer/customer-view/${row.customerMobile}`
    });
    window.open(href, "_blank");
  }
}
